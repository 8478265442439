import axios from 'axios';
import endpoints from '@/config/endpoints';
import _memoize from 'lodash/memoize';
import { getLocationAliasOrId } from '@/hostnames';
import type { Tenant } from 'niara-spear-crudmodel';
export type MinimumTenant = Pick<Tenant, 'id' | 'alias' | 'alias_replaced' | 'tenantId' | 'systemLocale' | 'userPoolClientId' | 'userPoolId' | 'supplierId' | 'modules_crs' | 'isBee2Pay' | 'DevOrHomologOrProd' | 'canary'>;
type GetTenantParam = {
  installationId?: string;
} | {
  aliasOrId?: string;
} | string;
export const getTenant = (optionsOrTenantId?: GetTenantParam): Promise<MinimumTenant> => {
  if (typeof optionsOrTenantId == 'string') {
    return getTenantByAliasOrIdOrTenantId(optionsOrTenantId);
  }
  const options = optionsOrTenantId;
  const aliasOrId = !options ? getLocationAliasOrId() : 'installationId' in options ? options.installationId : 'aliasOrId' in options ? options.aliasOrId : getLocationAliasOrId();
  return getTenantByAliasOrIdOrTenantId(aliasOrId);
};
export const getTenantId = (options?: GetTenantParam): Promise<string> => {
  return getTenant(options).then(r => r.tenantId);
};
const getTenantByAliasOrIdOrTenantId = _memoize(async (aliasOrId: string): Promise<MinimumTenant> => {
  if (aliasOrId) {
    return await axios.get(endpoints['niara-spear-commons'].endpoint + `/tenants/${encodeURI(aliasOrId)}`).then(r => r.data);
  }
  return null;
});
export default getTenant;